<template>
  <div>
    <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div v-if="!isSpinner">
        <validation-observer ref="promotionNotification">
            <b-row style="display:block;">
                <b-col cols="6">
                <b-form-group
                    label="Title"
                    label-for="h-Title"
                    label-cols-md="2"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Title"
                        rules="required"
                    >
                        <b-form-input
                            id="h-Title"
                            type="text"
                            placeholder="Title"
                            maxlength="50"
                            v-model="title"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col cols="6">
                <b-form-group
                    label="Description"
                    label-for="h-Description"
                    label-cols-md="2"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="Description"
                        rules="required"
                    >
                        <b-form-textarea
                            id="h-Description"
                            maxlength="500"
                            placeholder="Description"
                            v-model="discription"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col cols="6">
                <b-form-group
                    label="Attachment"
                    label-for="h-Attachment"
                    label-cols-md="2"
                >           
                    <!-- <validation-provider
                        #default="{ errors }"
                        name="Attachment"
                        rules="required"
                    > -->
                        <b-form-file
                            v-model="attachment"
                            @input="attachmentUpload()"
                        />
                        <div class="mt-1">
                            <strong>{{ attachment ? `Selected file: ${attachment.name}` : '' }}</strong>
                        </div>
                        <!-- <small class="text-danger">{{ errors[0] }}</small> -->
                        <small class="text-danger">{{ errorMessage }}</small>
                    <!-- </validation-provider> -->
                </b-form-group>
                </b-col>
                <b-col cols="6">
                    <b-form-group
                        label="Receiver"
                        label-for="h-Receiver"
                        label-cols-md="2"
                    >
                        <div class="demo-inline-spacing">
                            <b-form-radio
                                v-model="Selected"
                                value="all"
                            >
                                All User
                            </b-form-radio>
                            <b-form-radio
                                v-model="Selected"
                                value="guest"
                            >
                                Guest
                            </b-form-radio>
                            <b-form-radio
                                v-model="Selected"
                                value="student"
                            >
                                All Student
                            </b-form-radio>
                            <b-form-radio
                                v-model="Selected"
                                value="custom"
                            >
                                Custom
                            </b-form-radio>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols="6" v-if="Selected == 'custom'">
                <b-form-group
                    label="Users"
                    label-for="h-SelectUser"
                    label-cols-md="2"
                >
                    <validation-provider
                        #default="{ errors }"
                        name="attachment"
                        rules="required"
                    >
                        <v-select
                            v-model="selectedUser"
                            multiple
                            label="name"
                            placeholder='Select'
                            :options="options"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <!-- <b-col offset-md="4">
                </b-col> -->
            </b-row>
            <div class="sendBtn" style="text-align:left;">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    class="mr-1"
                    @click="sendNotification"
                    :disabled="onlyRead"
                >
                    Send
                </b-button>
            </div>
        </validation-observer>
        </div>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { required,min,max } from 'vee-validate/dist/rules'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BFormRadio,BFormFile,BFormSelect,BSpinner,BFormTextarea
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
extend('required',required);
extend('min',min);
extend('max',max);
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore()
import { dbCollections } from "@/utils/firebaseCollection.js";
import * as uploadImageFile from '@/utils/FirebaseQueries/storageQuery/storageQueries';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
    components: {
        BCardCode,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormRadio,
        BFormFile,
        BFormSelect,
        vSelect,
        ValidationProvider,
        ValidationObserver,
        BSpinner,
        BFormTextarea
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            Selected: "all",
            attachment: null,
            selectedUser: [],
            title:"",
            discription: "",
            options: [],
            errorMessage: "",
            isSpinner: false,
            onlyRead:false,
        }
    },
    created() {
        this.$root.$on('stopSpinner',()=>{
            this.isSpinner = false;
        });
        let userAccess = JSON.parse(localStorage.getItem('userAccess'));
        if(userAccess) {
            let index = userAccess.findIndex((ele)=>{
                return ele.name === "Announcement"
            })
            if(index !== -1) {
                if(userAccess[index].allow.toLowerCase() == 'read') {
                    this.onlyRead = true;
                }
            }
        }
        db.collection(dbCollections.USERS).get().then((data)=>{
            if (data.empty) {
                conole.error('nodataFoundInUser');
                return;
            }
            data.docs.forEach((ele)=>{
                if (ele.data().role == 'student') {
                    let obj = {
                        name: `${ele.data().firstName} ${ele.data().lastName}`,
                        id: ele.data().id
                    }
                    this.options.push(obj);
                }
            })
        })
    },
    methods: {
        sendNotification(){
            try {             
                var self = this;
                self.$refs.promotionNotification.validate().then((valid) => {
                    if (valid && self.errorMessage == '') {
                        this.isSpinner = true;
                        if (this.attachment !== null) {
                            uploadImageFile.uploadImageFileWithoutUniqueString('promotionalAttachment',this.attachment.name,this.attachment,(resp)=>{
                                let obj = {
                                    createdAt: new Date(),
                                    updatedAt: new Date(),
                                    title: this.title,
                                    discription: this.discription,
                                    reciver: this.Selected,
                                    attachment: resp.imageUrl,
                                    attachmentName: this.attachment.name
                                }
                                if (this.Selected == 'custom') {
                                    let id = [];
                                    this.selectedUser.forEach((data)=>{
                                        id.push(data);
                                    })
                                    obj.userId = id;
                                }
                                let doc = db.collection(dbCollections.POMOTIONALNOTIFICATION).doc();
                                obj.id = doc.id;
                                doc.set(obj).then(()=>{
                                    let notificationObj = {
                                        type: this.Selected,
                                        title: this.title,
                                        description: this.discription,
                                        promotionalId: obj.id
                                    }
                                    if (this.Selected == 'custom') {
                                        let id = [];
                                        this.selectedUser.forEach((data)=>{
                                            id.push(data.id);
                                        });
                                        notificationObj.userIds = id;
                                    }
                                    this.$axios.post(dbCollections.APIURL+'api/v1/promotional',notificationObj).then((resp)=>{
                                        if (resp.data.status) {
                                            this.isSpinner = false;
                                            this.$toast({
                                                component: ToastificationContent,
                                                position: 'top-right',
                                                props: {
                                                    title: 'Notification sent successfully .',
                                                    icon: 'CoffeeIcon',
                                                    variant: 'success',                               
                                                },
                                            });
                                            this.$router.push({name: 'promotional-notification'});
                                        } else {
                                            this.isSpinner = false;
                                            this.$toast({
                                                component: ToastificationContent,
                                                position: 'top-right',
                                                props: {
                                                    title: 'Notification not sent',
                                                    icon: 'CoffeeIcon',
                                                    variant: 'danger',                               
                                                },
                                            });
                                        }
                                    }).catch((error)=>{
                                        console.error(error);
                                        this.isSpinner = false;
                                    });
                                }).catch((error)=>{
                                    this.isSpinner = false;
                                    console.error(error);
                                })
                            });
                        } else {
                            let obj = {
                                createdAt: new Date(),
                                updatedAt: new Date(),
                                title: this.title,
                                discription: this.discription,
                                reciver: this.Selected,
                            }
                            if (this.Selected == 'custom') {
                                let id = [];
                                this.selectedUser.forEach((data)=>{
                                    id.push(data);
                                })
                                obj.userId = id;
                            }
                            let doc = db.collection(dbCollections.POMOTIONALNOTIFICATION).doc();
                            obj.id = doc.id;
                            doc.set(obj).then(()=>{
                                // this.isSpinner = false;
                                let notificationObj = {
                                    type: this.Selected,
                                    title: this.title,
                                    description: this.discription,
                                    promotionalId: obj.id
                                }
                                if (this.Selected == 'custom') {
                                    let id = [];
                                    this.selectedUser.forEach((data)=>{
                                        id.push(data.id);
                                    })
                                    notificationObj.userIds = id;
                                }
                                this.$axios.post(dbCollections.APIURL+'api/v1/promotional',notificationObj).then((resp)=>{
                                if (resp.data.status) {
                                    this.isSpinner = false;
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: 'Notification sent successfully.',
                                            icon: 'CoffeeIcon',
                                            variant: 'success',                               
                                        },
                                    });
                                    this.$router.push({name: 'promotional-notification'});
                                } else {
                                    this.isSpinner = false;
                                    this.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: 'Notification not sent',
                                            icon: 'CoffeeIcon',
                                            variant: 'danger',                               
                                        },
                                    });
                                }
                            }).catch((error)=>{
                                console.error(error);
                                this.isSpinner = false;
                            });
                            }).catch((error)=>{
                                this.isSpinner = false;
                                console.error(error);
                            })
                        }
                    } else {
                        this.isSpinner = false;
                    }
                });
            } catch (error) {
                this.isSpinner = false;
                console.error(error);
            }
        },
        attachmentUpload(){
            try {
                this.errorMessage = "";
                if (!(this.attachment.type == 'image/png' || this.attachment.type == 'image/jpg' || this.attachment.type == 'image/jpeg'||this.attachment.type == 'jpg' || this.attachment.type =='png' ||this.attachment.type == 'jpeg' || this.attachment.type == 'application/pdf' || this.attachment.type == 'image/svg+xml')) {
                    this.errorMessage = 'Uploaded file is not a valid. Only JPG, PNG, JPEG, and PDF files are allowed.'
                }
                else if (this.attachment.size >= 5000000 ) {
                    this.errorMessage = 'File should be less than 5 mb'
                } 
            } catch (error) {
                console.error(error);
            }
        }
    },
};
</script>

<style>

</style>